import React, { useState } from "react";
import {dishes, saveDishes} from "./dishes";
import {products, saveProducts} from "./products";
import meals from "./meals";
import { Product, ProductEaten } from "./types/Product";
import ChartCard from "./components/ChartCard";
import MealCard from "./components/MealCard";
import Modal from "./components/Modal";
import CalorieBudget from "./components/CalorieBudget";

import DateSwitcher from "./components/DateSwitcher";

import { v4 as generateUniqueId } from "uuid";
import { CalendarDay } from "./types/Date";
import AddDishesModal from "./components/AddDishesModal";
import {Dish} from "./types/Dish";
import AddProductModal from "./components/AddProductModal";

function App() {
  let localFoods;
  if (localStorage.getItem("eatenFoods")) {
    localFoods = JSON.parse(localStorage.getItem("eatenFoods") || "[]");
  } else {
    localFoods = [];
  }

  const [selectedFood, setSelectedFood] = useState<ProductEaten | null>(null);

  const saveToLocalStorage = (foodsEaten: any) => {
    localStorage.setItem("eatenFoods", JSON.stringify(foodsEaten));
  };

  const [dishesData, setDishesData] = useState<Dish[]>(dishes);

  const onSaveDishes = (dish: Dish) => {
    const newDishes = [...dishesData].concat(dish);
    setDishesData(newDishes);
    saveDishes(newDishes);
  }

  const [productsData, setProductsData] = useState<Product[]>(products);

  const onSaveProduct = (product: Product) => {
    const newProducts = [...productsData].concat(product);
    setProductsData(newProducts);
    saveProducts(newProducts);
  }

  const [selectedMeal, setSelectedMeal] = useState<string>("");
  const [foodsEaten, setFoodsEaten] = useState<ProductEaten[]>(localFoods);

  const [calorieBudget, setCalorieBudget] = useState<number>(
    Number(localStorage.getItem("calorieBudget") || 2084)
  );

  const today = new Date();
  const [selectedDay, setSelectedDay] = useState<CalendarDay>([
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  ]);

  const filteredFoodsEaten = foodsEaten.filter((food) => {
    const foodDate = new Date();
    foodDate.setFullYear(food.date[0]);
    foodDate.setMonth(food.date[1]);
    foodDate.setDate(food.date[2]);

    const today = new Date();
    today.setMonth(selectedDay[1]);
    today.setFullYear(selectedDay[0]);
    today.setDate(selectedDay[2]);

    const yesterday = new Date();
    yesterday.setMonth(selectedDay[1]);
    yesterday.setFullYear(selectedDay[0]);
    yesterday.setDate(selectedDay[2]);
    yesterday.setDate(yesterday.getDate() - 1);

    return foodDate <= today && foodDate > yesterday;
  });

  return (
    <div className="app">
      <div className="container">
          <div className="app-header shadow-sm bg-white mt-4">
          <div className="row mt-4 mt-md-4 mt-lg-0">
              <div className="col-lg-5">
                <DateSwitcher
                  selectedDay={selectedDay}
                  goToNextDay={() => {
                    const today = new Date();
                    today.setMonth(selectedDay[1]);
                    today.setFullYear(selectedDay[0]);
                    today.setDate(selectedDay[2]);
                    today.setDate(today.getDate() + 1);
                    const tomorrow: CalendarDay = [
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDate(),
                    ];
                    setSelectedDay(tomorrow);
                  }}
                  goToPreviousDay={() => {
                    const today = new Date();
                    today.setMonth(selectedDay[1]);
                    today.setFullYear(selectedDay[0]);
                    today.setDate(selectedDay[2]);
                    today.setDate(today.getDate() - 1);
                    const yesterday: CalendarDay = [
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDate(),
                    ];
                    setSelectedDay(yesterday);
                  }}
                />
              </div>
              <div className="col-lg-7">
                <CalorieBudget
                  calorieBudget={calorieBudget}
                  onChange={(event) => {
                    const value = event.target.value;
                    setCalorieBudget(Number(value));
                    localStorage.setItem("calorieBudget", value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4 mt-lg-0 mt-md-0">
          <div className="col-lg-5">
            <ChartCard
              foodList={productsData}
              foodsEaten={filteredFoodsEaten}
              calorieBudget={calorieBudget}
            />
          </div>
          <div className="col-lg-7">
            {meals.map((meal, id) => (
              <MealCard
                key={id}
                meal={meal.name}
                foodList={productsData}
                foodsEaten={filteredFoodsEaten}
                selectedMeal={selectedMeal}
                onSelect={(food) => {
                  setSelectedMeal(meal.name);
                  setSelectedFood(food as ProductEaten);
                }}
                onDelete={(id) => {
                  const foodsEatenUpdated = foodsEaten.filter(
                    (food) => food.id !== id
                  );
                  setFoodsEaten(foodsEatenUpdated);
                  saveToLocalStorage(foodsEatenUpdated);
                }}
              />
            ))}
            <Modal
              onSelectFood={setSelectedFood}
              selectedFood={selectedFood}
              selectedDay={selectedDay}
              onProcess={(foodEaten) => {
                const foodsEatenNew = [...foodsEaten];
                const existingIndex = foodsEatenNew.findIndex(fe => fe.id === foodEaten.id);

                if (existingIndex > -1) {
                  foodsEatenNew[existingIndex] = {
                    ...foodsEatenNew[existingIndex],
                    weight: foodEaten.weight,
                    meal: foodEaten.meal
                  }
                } else {
                  foodsEatenNew.push({
                    ...foodEaten,
                    id: generateUniqueId(),
                    calories: foodEaten.calories * foodEaten.weight / 100,
                    carbs: foodEaten.carbs * foodEaten.weight / 100,
                    protein: foodEaten.protein * foodEaten.weight / 100,
                    fat: foodEaten.fat * foodEaten.weight / 100,
                  });
                }

                setFoodsEaten(foodsEatenNew);
                saveToLocalStorage(foodsEatenNew);
              }}
              foodList={productsData}
              dishes={dishesData}
              selectedMeal={selectedMeal}
              foodsEaten={filteredFoodsEaten}
            />
          </div>
          <div className="col-lg-12">
            <button
              type="button"
              className="btn btn-success w-100 mb-3"
              aria-label="Add product"
              title="Add product"
              data-bs-toggle="modal"
              data-bs-target="#addProductModal"
            >
              Add product
            </button>

            <button
              type="button"
              className="btn btn-success w-100 mb-3"
              aria-label="Add Dishes"
              title="Add Dishes"
              data-bs-toggle="modal"
              data-bs-target="#addDishesModal"
            >
              Add dishes
            </button>

            <AddProductModal onProcess={onSaveProduct} />
            <AddDishesModal onProcess={onSaveDishes} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

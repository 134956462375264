import React, { FC, useState } from "react";
import {Product} from "../types/Product";
import {v4 as generateUniqueId} from "uuid";

export const AddProductModal: FC<{
  onProcess: (dish: Product) => void;
}> = ({ onProcess }) => {
  const [name, setName] = useState<string>("");
  const [calories, setCalories] = useState<number>();
  const [protein, setProtein] = useState<number>();
  const [carbs, setCarbs] = useState<number>();
  const [fat, setFat] = useState<number>();

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeCalories = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalories(+e.target.value);
  };

  const handleChangeProtein = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProtein(+e.target.value);
  };

  const handleChangeCarbs = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCarbs(+e.target.value);
  };

  const handleChangeFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFat(+e.target.value);
  };

  const handleSave = () => {
    onProcess({
      id: generateUniqueId(),
      name,
      calories: calories ?? 0,
      protein: protein ?? 0,
      carbs: carbs ?? 0,
      fat: fat ?? 0
    });
    setName('');
    setCalories(undefined);
    setProtein(undefined);
    setCarbs(undefined);
    setFat(undefined);
  };

  return (
    <>
      <div
        className="modal fade"
        id="addProductModal"
        aria-hidden="true"
        aria-labelledby="addProductModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="addProductModalLabel">
                Add product
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="search-form">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Name"
                  value={name || ''}
                  onChange={(e) => handleChangeName(e)}
                />

                <label className="form-label">Calories</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Calories"
                  value={calories || ''}
                  onChange={(e) => handleChangeCalories(e)}
                />

                <label className="form-label">Protein</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Protein"
                  value={protein || ''}
                  onChange={(e) => handleChangeProtein(e)}
                />


                <label className="form-label">Fat</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Fat"
                  value={fat || ''}
                  onChange={(e) => handleChangeFat(e)}
                />

                <label className="form-label">Carbs</label>
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Carbs"
                  value={carbs || ''}
                  onChange={(e) => handleChangeCarbs(e)}
                />

                <button
                  type="button"
                  className="btn btn-success w-100"
                  aria-label="Save"
                  title="Save"
                  data-bs-dismiss="modal"
                  disabled={!name || !calories || !protein || !carbs || !fat}
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductModal;

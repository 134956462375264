import React, { FC, useState } from "react";
import { Product, ProductEaten } from "../types/Product";
import FoodItem from "./FoodItem";
import DonutChart from "./DonutChart";
import { CalendarDay } from "../types/Date";
import {Dish} from "../types/Dish";
import meals from "../meals";

export const Modal: FC<{
  foodList: Product[];
  dishes: Dish[];
  selectedMeal: string;
  foodsEaten: ProductEaten[];
  selectedDay: CalendarDay;
  onProcess: (foodEaten: ProductEaten) => void;
  selectedFood: ProductEaten|null;
  onSelectFood: (food: ProductEaten) => void;
}> = ({ foodList, dishes, selectedMeal, onProcess, foodsEaten, selectedFood, onSelectFood, selectedDay }) => {

  const [weight, setWeight] = useState<number>(selectedFood?.weight ?? 100);
  const [dish, setDish] = useState<string>();
  const [meal, setMeal] = useState<string>(selectedFood?.meal ?? '');
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showFilteredFoods, setShowFilteredFoods] = useState<boolean>(!searchQuery);
  const editMultiplier = selectedFood?.meal ? 100 / selectedFood?.weight : 1;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setShowFilteredFoods(true);
    if (e.target.value === "") {
      setShowFilteredFoods(false);
    }
  };

  const selectedDish = dishes.find(d => d.id === dish);
  const clearWeight = Math.max(weight - (selectedDish?.weight ?? 0), 0);
  const filteredFoods = !searchQuery ? foodList : foodList.filter((entry) =>
    Object.values(entry).some(
      (val) => typeof val === "string" && val.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  React.useEffect(() => {
    setWeight(selectedFood?.weight ?? 100);
    setMeal(selectedFood?.meal ?? '');
  }, [selectedFood]);

  return (
    <>
      <div
        className="modal fade"
        id="searchModal"
        aria-hidden="true"
        aria-labelledby="searchModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="searchModalLabel">
                Search Food
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="search-form">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type and search a food..."
                  value={searchQuery}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="search-content" style={{ minHeight: 200 }}>
                {showFilteredFoods && (
                  <div className="food-list mt-3">
                    {filteredFoods.map((food, id) => (
                      <FoodItem
                        key={id}
                        onSelect={() => {
                          onSelectFood(food as ProductEaten);
                        }}
                        id={food.id}
                        name={food.name}
                        carbs={Math.round(food.carbs)}
                        protein={Math.round(food.protein)}
                        fat={Math.round(food.fat)}
                        calories={food.calories}
                        selectedMeal={selectedMeal}
                        foodList={foodList}
                        foodsEaten={foodsEaten}
                        isEditable={false}
                        onDelete={() => {}}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="foodModal"
        aria-hidden="true"
        aria-labelledby="FoodModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title text-capitalize ms-2" id="foodModal">
                {selectedFood && selectedFood.name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedFood && (
                <div className="d-flex align-items-center">
                  <div style={{ flex: 1 }}>
                    <div className="nutrition-detail protein">
                      Protein:{" "}
                      <span>
                        {Math.round(selectedFood.protein * editMultiplier * clearWeight / 100)}g
                      </span>
                    </div>
                    <div className="nutrition-detail fat">
                      Fat:{" "}
                      <span>{Math.round(selectedFood.fat * editMultiplier * clearWeight / 100)}g</span>
                    </div>
                    <div className="nutrition-detail carbs">
                      Carbs:{" "}
                      <span>{Math.round(selectedFood.carbs * editMultiplier * clearWeight / 100)}g</span>
                    </div>
                  </div>
                  <div
                    style={{ flex: 1 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <DonutChart
                      selectedFood={selectedFood}
                      quantity={clearWeight * editMultiplier / 100}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex food-detail-options mt-3 gap-4">
                <div className="d-flex flex-column">
                  Weight:
                  <input
                    className="form-control"
                    type="number"
                    value={weight || ''}
                    onChange={(event) => {
                      setWeight(Number(event.target.value))
                    }}
                  />
                </div>
                {!selectedFood?.meal && (
                  <div className="d-flex flex-column">
                    Dish:
                    <select
                      className="form-select form-select-md mb-1"
                      aria-label=".form-select-md example"
                      value={dish}
                      onChange={(event) => {
                        setDish(event.target.value);
                      }}
                    >
                      {dishes.map((dish) => <option key={dish.id} value={dish.id}>{dish.name}</option>)}
                    </select>
                  </div>
                )}

                {selectedFood?.meal && (
                  <div className="d-flex flex-column">
                    Meal:
                    <select
                      className="form-select form-select-md mb-1"
                      aria-label=".form-select-md example"
                      value={meal}
                      onChange={(event) => {
                        setMeal(event.target.value);
                      }}
                    >
                      {meals.map((m) => <option key={m.name} value={m.name}>{m.name}</option>)}
                    </select>
                  </div>
                )}

              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  if (selectedFood) {
                    const foodEaten: ProductEaten = {
                      ...selectedFood,
                      meal: (meal ?? selectedMeal) || "breakfast",
                      weight: clearWeight,
                      date: selectedDay,
                    };
                    onProcess(foodEaten);
                    setWeight(100);
                    setMeal('');
                  }
                }}
                className="btn btn-success w-100"
                data-bs-dismiss="modal"
                disabled={!weight || (!dish && !selectedFood?.meal) || clearWeight <= 0}
              >
                Add Food
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

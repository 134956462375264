const meals = [
    {
        id: "kahvalti",
        name: "breakfast",
      },
      {
        id: "oglenyemegi",
        name: "lunch",
      },
      {
        id: "aksamyemegi",
        name: "dinner",
      },
      {
        id: "atistirmalik",
        name: "snacks",
      },
]

export default meals;
import React, { FC, useState } from "react";
import {Dish} from "../types/Dish";
import {v4 as generateUniqueId} from "uuid";

export const AddDishesModal: FC<{
  onProcess: (dish: Dish) => void;
}> = ({ onProcess }) => {
  const [name, setName] = useState<string>("");
  const [weight, setWeight] = useState<string>("");

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(e.target.value);
  };

  const handleSave = () => {
    onProcess({
      id: generateUniqueId(),
      name,
      weight: +weight
    });
    setName('');
    setWeight('');
  };

  return (
    <>
      <div
        className="modal fade"
        id="addDishesModal"
        aria-hidden="true"
        aria-labelledby="addDishesModalLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="addDishesModalLabel">
                Add dishes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="search-form">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Dish name"
                  value={name}
                  onChange={(e) => handleChangeName(e)}
                />
                <input
                  type="number"
                  className="form-control mb-3"
                  placeholder="Dish weight"
                  value={weight}
                  onChange={(e) => handleChangeWeight(e)}
                />

                <button
                  type="button"
                  className="btn btn-success w-100"
                  aria-label="Save"
                  title="Save"
                  data-bs-dismiss="modal"
                  disabled={!name || !weight}
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDishesModal;
